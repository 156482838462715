<template>
    <div class="mx-auto pt-16">
        <project-single v-if="projectLightboxed !== undefined"
            :project="projectLightboxed">
        </project-single>

        <div v-if="isLoadingProjects" class="h-screen">
                <img class="mx-auto h-full"
                    src="/loading.svg">
            <div>
            </div>
        </div>
        <template v-else>
            <div class="grid gap-4 max-w-screen-xl mx-auto place-items-center p-4
                md:grid-cols-2">
                <project-card v-for="project in projects"
                    :key="project"
                    :project="project">
                </project-card>
            </div>
        </template>
    </div>
</template>

<script>
import { useRoute } from 'vue-router'
import meta from '../../AppMeta.js'
import ProjectCard from './ProjectCard.vue'
import ProjectSingle from './ProjectSingle.vue'

export default {
    components: {
        ProjectCard,
        ProjectSingle
    },
    computed: {
        projectsCached () {
            return this.$store.state.projects
        },
        projectLightboxed () {
            return this.$store.state.projectLightboxed
        }
    },
    data: () => ({
        projects: undefined,
        isLoadingProjects: true,
        route: useRoute(),
        meta: {
            title: 'G.A. Corvera Construction Corporation | Projects',
            tags: [
                {
                    property: 'og:title',
                    content: 'G.A. Corvera Construction Corporation | Projects'
                }, {
                    property: 'og:url',
                    content: 'https://gacorvera.com/projects'
                }, {
                    name: 'description',
                    content: 'Projects of G.A. Corvera Construction Corporation'
                }, {
                    property: 'og:description',
                    content: 'Projects of G.A. Corvera Construction Corporation'
                }, {
                    property: 'og:image',
                    content: 'https://content.gacorvera.com/gac-logo-720.png'
                }
            ]
        }
    }),
    async created() {
        this.projects = this.projectsCached
        this.isLoadingProjects = false
    },
    mounted() {
        if (this.route.path === '/projects') {
            meta.update(this.meta)
        }
    }
}
</script>
